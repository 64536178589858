var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "schedul-detail" }, [
    _c(
      "div",
      { staticClass: "btn-container" },
      [
        ![4, 5].includes(_vm.jobData.status) &&
        _vm.jobData.status &&
        !_vm.isCanceled &&
        _vm.hasPermission(_vm.$permissionFunctionCode.EDIT) &&
        _vm.isJobCreatorEdit
          ? _c(
              "el-button",
              {
                staticStyle: { "min-width": "100px" },
                attrs: {
                  type: "danger",
                  size: "small",
                  round: "",
                  loading: _vm.cancelLoading,
                },
                on: { click: _vm.cancelJop },
              },
              [_vm._v(" Cancel Job ")]
            )
          : _vm._e(),
        ![3, 4, 5].includes(_vm.jobData.status) && _vm.isJobCreatorEdit
          ? _c(
              "el-button",
              {
                staticStyle: { "min-width": "100px" },
                attrs: {
                  type: "primary",
                  size: "small",
                  round: "",
                  loading: _vm.submitLoading,
                },
                on: { click: _vm.submitForm },
              },
              [_vm._v(" Submit ")]
            )
          : _vm._e(),
        _c(
          "el-button",
          {
            staticStyle: { "min-width": "100px" },
            attrs: { outline: "", size: "small", round: "" },
            on: {
              click: function ($event) {
                _vm.$emit("close", (_vm.noAction = true))
              },
            },
          },
          [_vm._v(" Close ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "overflow-y": "auto", height: "calc(100% - 42px)" } },
      [
        _c(
          "el-form",
          {
            ref: "schedulForm",
            attrs: {
              model: _vm.schedulFormData,
              disabled:
                _vm.isCanceled ||
                [3, 4, 5].includes(_vm.status) ||
                !_vm.isJobCreatorEdit,
              "label-position": "top",
              "validate-on-rule-change": false,
            },
          },
          [
            _vm.jobData.status && [3, 4].includes(_vm.jobData.status)
              ? _c(
                  "base-detail-card",
                  { attrs: { title: "Files" } },
                  [
                    _c("job-files", {
                      attrs: { jobFiles: _vm.jobFiles },
                      on: {
                        "update:jobFiles": function ($event) {
                          _vm.jobFiles = $event
                        },
                        "update:job-files": function ($event) {
                          _vm.jobFiles = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.jobData.status && [3, 4].includes(_vm.jobData.status)
              ? _c(
                  "base-detail-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: !_vm.jobDataInner,
                        expression: "!jobDataInner",
                      },
                    ],
                    attrs: { title: "STS Information" },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "deliveredScheduleForm",
                        staticClass: "main-scroll-form",
                        attrs: {
                          model: _vm.jobDataInner,
                          "label-position": "top",
                          "validate-on-rule-change": false,
                        },
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 10 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { sm: 12, md: 8, lg: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "Product Name" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "single-border",
                                      attrs: {
                                        readonly: "",
                                        value: (_vm.jobDataInner || {})
                                          .productName,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, md: 8, lg: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "Barge Name" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "single-border",
                                      attrs: {
                                        readonly: "",
                                        value: (_vm.jobDataInner || {})
                                          .bargeName,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, md: 8, lg: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { label: "eBDN Date" },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "single-border",
                                      attrs: { type: "date", readonly: "" },
                                      model: {
                                        value: (_vm.jobDataInner || {}).docDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.jobDataInner || {},
                                            "docDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "(jobDataInner || {}).docDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, md: 8, lg: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "Delivered Quantity" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "single-border",
                                      attrs: {
                                        readonly: "",
                                        value: _vm.numericByCompanySetting(
                                          (_vm.jobDataInner || {}).docQty
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, md: 8, lg: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "ETC" } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "single-border",
                                      attrs: { type: "date", readonly: "" },
                                      model: {
                                        value: (_vm.jobDataInner || {}).etc,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.jobDataInner || {},
                                            "etc",
                                            $$v
                                          )
                                        },
                                        expression: "(jobDataInner || {}).etc",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, md: 8, lg: 4 } },
                              [
                                (_vm.jobDataInner || {}).bdnPdfId
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { label: "BDN Document" } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "primary",
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.previewFileMixin(
                                                  (_vm.jobDataInner || {})
                                                    .bdnPdfId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  (_vm.jobDataInner || {})
                                                    .docNumber
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.jobDataInner?.timesheet
                          ? _c("time-sheet-information", {
                              attrs: { jobDataInner: _vm.jobDataInner },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "base-detail-card",
              { attrs: { title: "Product Information" } },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "sysProductId",
                              label: "Product Name",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "Select",
                                  loading: !_vm.sysProductOptions.length > 0,
                                },
                                on: {
                                  "visible-change":
                                    _vm.getProductListSortByRecent,
                                  change: _vm.handleChangeProduct,
                                },
                                model: {
                                  value: _vm.schedulFormData.sysProductId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.schedulFormData,
                                      "sysProductId",
                                      $$v
                                    )
                                  },
                                  expression: "schedulFormData.sysProductId",
                                },
                              },
                              _vm._l(_vm.sysProductOptions, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "sysProductSpecificationId",
                              label: "Spec",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "Select" },
                                model: {
                                  value:
                                    _vm.schedulFormData
                                      .sysProductSpecificationId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.schedulFormData,
                                      "sysProductSpecificationId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "schedulFormData.sysProductSpecificationId",
                                },
                              },
                              _vm._l(
                                _vm.sysProductSpecificationOptions,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "finalQty",
                              label: "Transfer Quantity",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-select",
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.schedulFormData.finalQty,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.schedulFormData,
                                      "finalQty",
                                      $$v
                                    )
                                  },
                                  expression: "schedulFormData.finalQty",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { slot: "append", disabled: "" },
                                    slot: "append",
                                    model: {
                                      value: _vm.schedulFormData.qtyUnitMetric,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.schedulFormData,
                                          "qtyUnitMetric",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "schedulFormData.qtyUnitMetric",
                                    },
                                  },
                                  [
                                    _c("el-option", { attrs: { value: "MT" } }),
                                    _c("el-option", {
                                      attrs: { value: "CBM" },
                                    }),
                                    _c("el-option", {
                                      attrs: { value: "BARRELS" },
                                    }),
                                    _c("el-option", { attrs: { value: "%" } }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "fuelTypeCode",
                              label: "Fuel Type (For MPA Reporting)",
                              rules: _vm.getFuelCodeRules,
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "Select",
                                  loading: !_vm.mpaFuelTypeOptions.length > 0,
                                },
                                on: {
                                  "visible-change":
                                    _vm.getMpaFuelTypeListSortByRecent,
                                },
                                model: {
                                  value: _vm.schedulFormData.fuelTypeCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.schedulFormData,
                                      "fuelTypeCode",
                                      $$v
                                    )
                                  },
                                  expression: "schedulFormData.fuelTypeCode",
                                },
                              },
                              _vm._l(_vm.mpaFuelTypeOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "referenceNo",
                              label: "Reference No.",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.schedulFormData.referenceNo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "referenceNo",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.referenceNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("fuel-type-related-information", {
                  attrs: { schedulFormData: _vm.schedulFormData },
                  on: {
                    "update:schedulFormData": function ($event) {
                      _vm.schedulFormData = $event
                    },
                    "update:schedul-form-data": function ($event) {
                      _vm.schedulFormData = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "base-detail-card",
              { attrs: { title: "Barge Information" } },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _vm.schedulFormData.mitigatingCompanyOrgId ===
                          _vm.currentCompany.id || !_vm.isEdit
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Delivering Barge Name",
                                  prop: "bargeName",
                                  rules: _vm.rules.reqRule,
                                },
                              },
                              [
                                _c("barge-or-vessel-selector", {
                                  attrs: {
                                    disabled: [2, 3, 4, 5].includes(
                                      _vm.jobData.status
                                    ),
                                    schedulFormData: _vm.schedulFormData,
                                    availableBargesLoading: false,
                                    availableBargesOptions:
                                      _vm.currentCompanyShips.filter(
                                        (item) =>
                                          !(
                                            (item.imo &&
                                              item.imo ===
                                                _vm.schedulFormData
                                                  .vesselImo) ||
                                            item.id ===
                                              _vm.schedulFormData.vesselShipId
                                          )
                                      ),
                                  },
                                  on: {
                                    handleChangeBarge: (val) =>
                                      _vm.changeVesselName(val, true),
                                    visibleChange: () => {},
                                  },
                                }),
                                _vm.supplyingBargeMpaStatus
                                  ? _c(
                                      "span",
                                      { staticStyle: { "font-size": "10px" } },
                                      [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.supplyingBargeMpaStatus
                                            ) +
                                            ")"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              { attrs: { label: "Delivering Barge Name" } },
                              [
                                _c("el-input", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.schedulFormData.bargeName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.schedulFormData,
                                        "bargeName",
                                        $$v
                                      )
                                    },
                                    expression: "schedulFormData.bargeName",
                                  },
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Delivering Barge IMO" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.schedulFormData.bargeImo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.schedulFormData, "bargeImo", $$v)
                                },
                                expression: "schedulFormData.bargeImo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Delivering Barge SB No." } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.supplyingBarge.licenceNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.supplyingBarge, "licenceNo", $$v)
                                },
                                expression: "supplyingBarge.licenceNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _vm.schedulFormData.mitigatingCompanyOrgId ===
                          _vm.currentCompany.id || !_vm.isEdit
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "Search Receiving Barge" } },
                              [
                                _c("search-vessel-selector", {
                                  attrs: {
                                    disabled:
                                      _vm.isCanceled ||
                                      [3, 4, 5].includes(_vm.status) ||
                                      !_vm.isJobCreatorEdit,
                                    searchedVesselList: _vm.searchedVesselList,
                                    schedulFormData: _vm.schedulFormData,
                                  },
                                  on: {
                                    "update:searchedVesselList": function (
                                      $event
                                    ) {
                                      _vm.searchedVesselList = $event
                                    },
                                    "update:searched-vessel-list": function (
                                      $event
                                    ) {
                                      _vm.searchedVesselList = $event
                                    },
                                    handleSelectVessel: (val) =>
                                      _vm.changeVesselName(val, false),
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              { attrs: { label: "Receiving Barge Name" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled:
                                      _vm.isCanceled ||
                                      [3, 4, 5].includes(_vm.status) ||
                                      !_vm.isJobCreatorEdit,
                                  },
                                  model: {
                                    value: _vm.schedulFormData.vesselName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.schedulFormData,
                                        "vesselName",
                                        $$v
                                      )
                                    },
                                    expression: "schedulFormData.vesselName",
                                  },
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Receiving Barge Name" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled:
                                  _vm.isCanceled ||
                                  [3, 4, 5].includes(_vm.status) ||
                                  !_vm.isJobCreatorEdit,
                              },
                              model: {
                                value: _vm.schedulFormData.vesselName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "vesselName",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.vesselName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "vesselImo",
                              label: "Receiving Barge IMO",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled:
                                  _vm.isCanceled ||
                                  [3, 4, 5].includes(_vm.status) ||
                                  !_vm.isJobCreatorEdit,
                              },
                              model: {
                                value: _vm.schedulFormData.vesselImo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "vesselImo",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.vesselImo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Receiving Barge SB No." } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled:
                                  _vm.isCanceled ||
                                  [3, 4, 5].includes(_vm.status) ||
                                  !_vm.isJobCreatorEdit,
                              },
                              model: {
                                value: _vm.receivingBarge.licenceNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.receivingBarge, "licenceNo", $$v)
                                },
                                expression: "receivingBarge.licenceNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "base-detail-card",
              { attrs: { title: "BDN Information" } },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "bargeOrgId",
                              label: "BDN",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  placeholder: "Select",
                                },
                                on: { change: _vm.handleChangeBDN },
                                model: {
                                  value: _vm.schedulFormData.bargeOrgId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.schedulFormData,
                                      "bargeOrgId",
                                      $$v
                                    )
                                  },
                                  expression: "schedulFormData.bargeOrgId",
                                },
                              },
                              _vm._l(
                                _vm.physicalSupplierOptions,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.isExxonMobil
                      ? _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "customerName",
                                  label: "Customer Name",
                                  rules: _vm.rules.reqRule,
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.schedulFormData.customerName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.schedulFormData,
                                        "customerName",
                                        $$v
                                      )
                                    },
                                    expression: "schedulFormData.customerName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "base-detail-card",
              { attrs: { title: "Location Information" } },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "port", label: "Port" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.schedulFormData.port,
                                callback: function ($$v) {
                                  _vm.$set(_vm.schedulFormData, "port", $$v)
                                },
                                expression: "schedulFormData.port",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "imo", label: "Date & Time" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "datetime",
                                format: "yyyy-MM-dd HH:mm",
                                placeholder: "Select",
                              },
                              model: {
                                value: _vm.schedulFormData.vesselEta,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "vesselEta",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.vesselEta",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "locationLevel",
                              label: "Anchorage/Berth",
                            },
                          },
                          [
                            _c("el-cascader", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                clearable: "",
                                "popper-class": "el-cascader-long",
                                options: _vm.locationList,
                                props: _vm.locationOptionProps,
                                "show-all-levels": false,
                              },
                              model: {
                                value: _vm.schedulFormData.locationLevel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "locationLevel",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.locationLevel",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "base-detail-card",
              { attrs: { title: "Remarks" } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", rows: 2 },
                          model: {
                            value: _vm.schedulFormData.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.schedulFormData, "remark", $$v)
                            },
                            expression: "schedulFormData.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form",
              [
                _vm.jobData.id
                  ? _c(
                      "base-detail-card",
                      { attrs: { title: "Audit Trails" } },
                      [
                        _c("audit-trails-information", {
                          attrs: { jobId: _vm.jobData.id },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.jobData.status === 4
                  ? _c(
                      "base-detail-card",
                      {
                        attrs: {
                          title: "MPA Transaction Information",
                          action: _vm.mpaInfoActions,
                        },
                      },
                      [
                        _c("mpa-transaction-information", {
                          attrs: { mpaTransactionData: _vm.mpaTransactionData },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.sgTradexState &&
                _vm.jobData.status === 4 &&
                _vm.sgTradexState.isSgTradexInfoVisible
                  ? _c(
                      "base-detail-card",
                      {
                        attrs: {
                          title: "SgTradex Transaction Information",
                          action: _vm.sgTradexState.actions,
                        },
                      },
                      [
                        _c("sg-tradex-transaction-information", {
                          attrs: {
                            sgTradexTransactionData:
                              _vm.sgTradexState.sgTradexTransactionData,
                            loading:
                              _vm.sgTradexState.sgTradexTransactionLoading,
                          },
                          on: {
                            "update:sgTradexTransactionData": function (
                              $event
                            ) {
                              return _vm.$set(
                                _vm.sgTradexState,
                                "sgTradexTransactionData",
                                $event
                              )
                            },
                            "update:sg-tradex-transaction-data": function (
                              $event
                            ) {
                              return _vm.$set(
                                _vm.sgTradexState,
                                "sgTradexTransactionData",
                                $event
                              )
                            },
                            "update:loading": function ($event) {
                              return _vm.$set(
                                _vm.sgTradexState,
                                "sgTradexTransactionLoading",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm.jobData.id
          ? _c("email-recipients", {
              attrs: {
                jobId: _vm.jobData.id,
                jobStatus: _vm.jobData.status,
                showDialog: _vm.showEmailRecipientDialog,
              },
              on: {
                "on-dialog-closed": () =>
                  (_vm.showEmailRecipientDialog = false),
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }